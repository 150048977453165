import { Enum } from "../enums/Enum";

export const ETypoColor = Object.freeze({
  WHITE: "text-white",
  BLACK: "text-black",
  GABLE_GREEN: "text-gableGreen",
  GABLE_GREEN_75: "text-gableGreen75",
  BRICK_RED: "text-brickRed",
  DARK_BLUE: "text-darkBlue",
  CORNFLOWER_BLUE: "text-cornflowerBlue",
  GABLE_GREEN_25: "text-gableGreen25",
  GABLE_GREEN_35: "text-gableGreen35",
  GABLE_GREEN_50: "text-gableGreen50",
  RUSTY_RED: "text-rustyRed",
  SUCCESS: "text-success",
  TOP_BANNER: "text-topBannerText",
  TOP_BANNER_BUTTON: "text-topBannerText",
  HEADER_TEXT: "text-headerText",
  HEADER_TEXT_HOVER: "text-headerTextHover",
  HEADER_TEXT_ACTIVE: "text-headerTextActive",
  BUTTON_SEARCH: "text-buttonSearchText",
  FILTER_BAR: "text-filterBarText",
  FOOTER: "text-footerText",
  CARD_HIGHLIGHTS: "text-highlightText",
  VENETIAN_RED: "text-venetianRed",
  MODAL_PROJECT_DETAIL_TEXT: "text-modalProjectDetailText",
  MODAL_PROJECT_DETAIL_SOLD_OUT: "text-modalProjectDetailSoldOut",
  MODAL_PROJECT_DETAIL_AVAILABLE: "text-modalProjectDetailAvailable",
  MODAL_PROJECT_DETAIL_SHOW_FLAT_APPOINTMENT:
    "text-modalProjectDetailShowFlatAppointmentText",
  MODAL_PROJECT_DETAIL_SOLD_OUT_BUTTON:
    "text-modalProjectDetailSoldOutButtonText",
  CONTACT_SALES_HEADER: "text-contactSalesHeader",
  CONTACT_SALES_AGENT: "text-contactSalesAgent",
  CONTACT_SALES_AGENT_ROLE: "text-contactSalesAgentRole",
  ERROR: "text-error",
  ENQUIRY_SUBMIT: "text-enquirySubmitText",
  ENQUIRY_HEADER: "text-enquiryHeader",
  PROJECT_CARD_DETAIL_TITLE: "text-projectCardDetailTitleText",
  TEXT: "text-color",
  TABLE_TEXT: "text-tableText",
  TABLE_HEADING_TEXT: "text-tableHeadingText",
  PROJECT_CARD_GO_TO_ENQUIRY: "text-projectCardGoToEnquiryText",
  PROJECT_CARD_GO_TO_ENQUIRY_BUTTON: "text-projectCardGoToEnquiryButtonText",
  CURATED_PROPERTIES_HEADING: "text-curatedPropertiesHeading",
  CONTACT_SALES: "text-contactSalesText",
  ENQUIRY: "text-enquiryText",
  FORM_CONTACT_US: "text-formContactUsText",
  TRANSPARENT: "text-transparent",
  BUTTON_CONTACT_US: "text-contactUsButtonText",
  CONTACT_US: "text-contactUsText",
  CARD: "text-cardText",
  CARD_CHIP: "text-cardChipText",
  CONTACT_SALES_SUBMIT: "text-contactSalesSubmitText",
  CARD_FEATURE: "text-cardFeatureText",
  CARD_FEATURE_ADDRESS: "text-cardFeatureAddressText",
  CARD_PROJECT: "text-cardProjectText",
  CARD_PROJECT_ADDRESS: "text-cardProjectAddressText",
  CARD_PROJECT_TENURE: "text-cardProjectTenureText",
  CARD_UNIT_TYPE_HEADING: "text-cardUnitTypeHeading",
  CARD_SOLD_OUT: "text-cardSoldOutText",
  PROJECT_HEADING: "text-projectHeadingText",
  FILTER_BAR_MOBILE: "text-filterBarMobileText",
  FILTER_BAR_MOBILE_BUTTON_TEXT: "text-filterBarMobileButtonText",
  CARD_BUTTON_ACTIVE: "text-cardButtonActiveText",
  CARD_BUTTON: "text-cardButtonText",
});

export const EBgColor = Object.freeze({
  WHITE: "bg-white",
  GABLE_GREEN: "bg-gableGreen",
  GABLE_GREEN_25: "bg-gableGreen25",
  BRICK_RED: "bg-brickRed",
  DARK_BLUE: "bg-darkBlue",
  DARK_GREY: "bg-darkGrey",
  CORNFLOWER_BLUE: "bg-cornflowerBlue",
  TRANSPARENT: "bg-transparent",
  BLACK: "bg-black",
  TOP_BANNER: "bg-topBannerBackground",
  TOP_BANNER_BUTTON: "bg-topBannerButtonBackground",
  HEADER: "bg-headerBackground",
  HEADER_BUTTON: "bg-headerButtonBackground",
  CARD_FEATURE_DIVIDER: "bg-cardFeatureDivider",
  CARD_PROJECT_DIVIDER: "bg-cardProjectDivider",
});

export const EBorder = Object.freeze({
  GABLE_GREEN: "border-gableGreen",
  BRICK_RED: "border-brickRed",
  DARK_BLUE: "border-darkBlue",
  CORNFLOWER_BLUE: "border-cornflowerBlue",
  WHITE: "border-white",
  NONE: "border-none",
  BLACK: "border-black",
});

export const EHeaderColor = Object.freeze({
  BACKGROUND: "bg-headerBackground",
  TEXT: "text-headerText",
  TEXT_HOVER: "text-headerTextHover",
  TEXT_ACTIVE: "text-headerTextActive",
  BUTTON_BACKGROUND: "bg-headerButtonBackground",
  BUTTON_TEXT: "text-headerButtonText",
});

export const ETopBannerColor = Object.freeze({
  BACKGROUND: "bg-topBannerBackground",
  TEXT: "text-topBannerText",
  BUTTON_BACKGROUND: "bg-topBannerButtonBackground",
  BUTTON_TEXT: "text-topBannerButtonText",
});

export const EButtonSearchColor = Object.freeze({
  BACKGROUND: "bg-buttonSearchBackground",
  TEXT: "text-buttonSearchText",
});

export const EHighlightColor = Object.freeze({
  BACKGROUND: "bg-highlightBackground",
  TEXT: "text-highlightText",
  ICON: "bg-highlightIcon",
});

export const EFooterColor = Object.freeze({
  BACKGROUND: "bg-footerBackground",
  TEXT: "text-footerText",
});

export type ETypoColor = Enum<typeof ETypoColor>;
export type EBgColor = Enum<typeof EBgColor>;
export type EBorder = Enum<typeof EBorder>;
export type EHeaderColor = Enum<typeof EHeaderColor>;
export type ETopBannerColor = Enum<typeof ETopBannerColor>;
export type EButtonSearchColor = Enum<typeof EButtonSearchColor>;
export type EHighlightColor = Enum<typeof EHighlightColor>;
export type EFooterColor = Enum<typeof EFooterColor>;
