import { Enum } from "../enums/Enum";

export const ETypoTag = Object.freeze({
  DIV: "div",
  H1: "h1",
  H2: "h2",
  H3: "h3",
  H4: "h4",
  H5: "h5",
  H6: "h6",
  P: "p",
  SPAN: "span",
});

export const ETypoVariant = Object.freeze({
  HEADER_48: "text-header-48 lg:text-header-20",
  HEADER_36: "text-header-36 lg:text-header-20",
  HEADER_32: "text-header-32",
  HEADER_24: "text-header-24",
  HEADER_PROJECT_24: "text-header-24 lg:text-header-20",
  HEADER_20: "text-header-20",
  HEADER_18: "text-header-18 lg:text-header-16",
  HEADER_16: "text-header-16 lg:text-header-14",
  HEADER_14: "text-header-14",
  SUB_TITLE_20: "text-sub-title-20 lg:text-sub-title-18",
  SUB_TITLE_18: "text-sub-title-18 lg:text-sub-title-20",
  SUB_TITLE_16: "text-sub-title-16",
  SUB_TITLE_14: "text-sub-title-14 lg:text-sub-title-12",
  SUB_TITLE_12: "text-sub-title-12",
  BODY_TITLE_16: "text-body-title-16",
  BODY_TITLE_14: "text-body-title-14",
  BODY_TITLE_12: "text-body-title-12",
});

export type ETypoTag = Enum<typeof ETypoTag>;
export type ETypoVariant = Enum<typeof ETypoVariant>;
